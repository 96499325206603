
















import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ModelDifferenceColorHighlightSvgMixin } from '@/model-difference/mixins/ModelDifferenceColorHighlightSvgMixin';

@Component
export default class ModelDifferenceColorHighlightSvg extends mixins(ModelDifferenceColorHighlightSvgMixin) {}
