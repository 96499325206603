import { JsonProperty, Serializable } from 'typescript-json-serializer';
import OperationDTO from '@/model-difference/models/OperationDTO';
import Model from '@/models/Model';

@Serializable()
export default class OperationsDTO {
  @JsonProperty({
    name: 'patch',
    type: OperationDTO,
  })
  private _patch: OperationDTO[] = [];
  @JsonProperty({
    name: 'integratedModel',
    type: Model,
  })
  private _integratedModel?: Model = undefined;

  constructor(patch: OperationDTO[] = []) {
    this._patch = patch;
  }

  get patch(): OperationDTO[] {
    return this._patch;
  }

  set patch(value: OperationDTO[]) {
    this._patch = value;
  }

  set integratedModel(value: Model | undefined) {
    this._integratedModel = value;
  }
  get integratedModel(): Model | undefined {
    return this._integratedModel;
  }
}
