import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class OperationDTO {
  @JsonProperty('op')
  private _op: string;
  @JsonProperty('path')
  private readonly _path: string;
  @JsonProperty('from')
  private _from?: string = undefined;
  @JsonProperty('value')
  private _value?: any = undefined;
  @JsonProperty('context')
  private _context?: any = undefined;

  constructor(operation: string, path: string) {
    this._op = operation;
    this._path = path;
  }

  set op(value: string) {
    this._op = value;
  }

  get op(): string {
    return this._op;
  }

  get path(): string {
    return this._path;
  }

  get from(): string | undefined {
    return this._from;
  }

  set from(value: string | undefined) {
    this._from = value;
  }

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
  }

  get context(): any {
    return this._context;
  }

  set context(value: any) {
    this._context = value;
  }
}
