import Component, { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { hasPermission } from '@/auth/AuthService';
import ModelConfigService from '@/services/ModelConfigService';
import Model from '@/models/Model';
import { PossibleAction } from '@/auth/PossibleAction';
import ModelConfig from '@/models/ModelConfig';

export interface ToastMessage {
  title: string;
  msg: string;
}

@Component({})
export class LoadModelConfig extends mixins(Toasts) {
  public loadModelConfig(model: Model): Promise<ModelConfig | ToastMessage> {
    if (hasPermission(PossibleAction.CAN_GET_CONFIG)) {
      if (model) {
        return ModelConfigService.getByID(model.modelConfigId).catch((backendError) => {
          let msg,
            title = 'Failed to load';
          if (backendError.response.status === 404) {
            msg = 'Could not find the config: ' + model.modelConfig + ' ' + model.modelConfigVersion;
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Oops, something failed: ' + backendError.response.status;
          }
          return {
            title,
            msg,
          };
        });
      }
    } else {
      return new Promise((resolve, reject) => {
        reject({
          title: 'Action denied',
          msg: 'You do not have the required rights.',
        });
      });
    }

    return new Promise((resolve) => {
      resolve({
        title: '',
        msg: '',
      });
    });
  }
}
