import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export class VModel extends Vue {
  @Prop({
    required: false,
    default: () => null,
  })
  // Ignore is OK, otherwise this would not work
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  protected value?: any;

  @Watch('value')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  protected handleValueChange(newVal: any): void {
    this.$emit('input', newVal);
  }
}

@Component({})
export class IntermediateValue<T> extends Vue {
  protected enteredValue: T | null = null;

  @Watch('value')
  protected handleValueChanged(newVal: T): void {
    this.enteredValue = newVal;
  }

  @Watch('enteredValue')
  protected handleEnteredValueChanged(newVal: T): void {
    this.$emit('input', newVal);
  }
}

@Component({})
export class LoadingMixin extends Vue {
  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
