



































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Model from '@/models/Model';
import ModelElement from '@/models/ModelElement';
import ModelConfig from '@/models/ModelConfig';
import Point from '@/models/Point';
import ModelElementType from '@/models/ModelElementType';
import Node from '@/models/Node';
import Edge from '@/models/Edge';
import ConfigSvgComponent from '@/components/Editor/ConfigSvgComponent.vue';
import SVGHelper from '@/serializer/SVGHelper';
import ModelDifferenceColorHighlightSvg from '@/model-difference/components/ModelDifferenceNodeColorHighlightSvg.vue';
import ModelDifferenceNodeColorHighlightSvg from '@/model-difference/components/ModelDifferenceNodeColorHighlightSvg.vue';
import ModelDifferenceEdgeColorHighlightSvg from '@/model-difference/components/ModelDifferenceEdgeColorHighlightSvg.vue';
import ModelDifferenceHighlightDTO from '@/model-difference/models/ModelDifferenceHighlightDTO';
import Moveable from '@/components/MovableComponent.vue';
import { mixins } from 'vue-class-component';
import { MovableMixin } from '@/mixins/EditorMixins';
import { VueSelecto } from 'vue-selecto';
import SelectedModelElement from '@/models/SelectedModelElement';
import Drawable from '@/models/drawables/Drawable';
import Bounds from '@/models/Bounds';
import { ResizeSensor } from 'css-element-queries';
import { v4 as uuidv4 } from 'uuid';
import EditorCanvasHelper from '@/serializer/EditorCanvasHelper';
import { findNodeWithId } from '@/serializer/helpers';

@Component({
  components: {
    VueSelecto,
    Moveable,
    ModelDifferenceEdgeColorHighlightSvg,
    ModelDifferenceNodeColorHighlightSvg,
    ModelDifferenceColorHighlightSvg,
    ConfigSvgComponent,
  },
})
export default class ModelDifferenceCanvas extends mixins(MovableMixin) {
  public internalId = uuidv4();

  protected readonly Edge = Edge;
  @Prop({
    required: true,
  })
  protected model!: Model;

  @Prop({
    required: false,
    default: () => 1,
  })
  protected scale!: number;

  @Prop({
    required: false,
    default: () => new Point(0, 0),
  })
  protected viewPort!: Point;

  @Prop({
    required: true,
  })
  protected modelConfig!: ModelConfig;

  @Prop({
    required: true,
  })
  protected modelDifferenceHighlights!: ModelDifferenceHighlightDTO[];

  protected internalScale = 1;

  protected loading = true;

  protected viewBox = '0 0 0 0';
  protected lastBounds: Bounds = new Bounds(0, 0);

  created(): void {
    this.internalScale = this.scale;
  }

  mounted(): void {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);

    let drawContainer = document.querySelector('#modelDifferenceDrawContainer-' + this.internalId);
    if (drawContainer) {
      new ResizeSensor(drawContainer, () => {
        this.calculateViewBox();
      });
    }

    window.setTimeout(() => {
      this.moveViewportToElements();
      window.setTimeout(() => {
        this.loading = false;
      }, 200);
    }, 200);
  }

  beforeDestroy(): void {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  protected handleKeyDown(event: KeyboardEvent): void {
    if (event.code.toLowerCase() === 'space') {
      event.preventDefault();
      event.stopPropagation();
      this.dragscrollEnabled = true;
    }
  }

  protected handleKeyUp(event: KeyboardEvent): void {
    if (event.code.toLowerCase() === 'space') {
      event.preventDefault();
      event.stopPropagation();
      this.dragscrollEnabled = false;
    }
  }

  @Watch('internalScale')
  protected handleInternalScaleChange(newVal: number): void {
    this.calculateViewBox(true);
    Vue.nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
      (this.$refs.moveable as any).updateRect();
    });
    this.$emit('scale-change', newVal);
  }

  @Watch('scale')
  protected handleScaleChange(newVal: number): void {
    this.calculateViewBox(true);
    Vue.nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
      (this.$refs.moveable as any).updateRect();
    });
    this.internalScale = newVal;
  }

  @Watch('internalViewPort')
  protected handleinternalViewPortChange(newVal: Point): void {
    this.$emit('view-port-change', newVal);
  }

  @Watch('viewPort')
  protected handleViewPortChange(newVal: Point): void {
    this.internalViewPort = newVal;
  }

  @Watch('selectedElements', { deep: true })
  public selectedElementChanged(newVal: SelectedModelElement[]): void {
    // do nothing
  }

  protected get modelElements(): ModelElement[] {
    return ([] as ModelElement[]).concat(this.model.nodes).concat(this.model.edges).concat(this.model.drawables);
  }

  protected handleMouseWheelScroll(event: WheelEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.deltaY <= 0) {
      // zoom in
      this.internalScale = Math.round((this.internalScale + 0.1) * 100) / 100;
      // limit to maximum of 300%
      if (this.internalScale > 3) {
        this.internalScale = 3;
      }
    } else {
      // zoom out
      this.internalScale = Math.round((this.internalScale - 0.1) * 100) / 100;
      // limit to minimum of 10%
      if (this.internalScale < 0.1) {
        this.internalScale = 0.1;
      }
    }
  }

  protected getComponentPrototype(modelElement: ModelElement): string {
    if (this.modelConfig) {
      let type: ModelElementType | null | undefined = null;
      if (modelElement instanceof Node) {
        type = this.modelConfig.getNodeType(modelElement.type);
      } else if (modelElement instanceof Edge) {
        type = this.modelConfig.getEdgeType(modelElement.type);
      }

      if (type) {
        return SVGHelper.createDynamicEdgePathIds(type.display);
      }
    }

    return '';
  }

  protected moveViewportToElements(): void {
    let minX = 99999;
    let minY = 99999;
    let maxX = 0;
    let maxY = 0;

    this.modelElements.forEach((modelElement) => {
      if (modelElement instanceof Node) {
        minX = Math.min(minX, modelElement.startPos.x);
        maxX = Math.max(maxX, modelElement.startPos.x + modelElement.bounds.width);
        minY = Math.min(minY, modelElement.startPos.y);
        maxY = Math.max(maxY, modelElement.startPos.y + modelElement.bounds.height);
      }
    });

    let viewportClientRect = (this.$refs.viewport as HTMLElement).getBoundingClientRect();

    let elementWidth = Math.abs(minX - maxX);
    let elementHeight = Math.abs(minY - maxY);

    this.internalViewPort.x = (minX - viewportClientRect.width / 2 + elementWidth / 2) * -1;
    this.internalViewPort.y = (minY - viewportClientRect.height / 2 + elementHeight / 2) * -1;
  }

  protected modelElementIsHighlighted(modelElementId: string): boolean {
    return this.modelDifferenceHighlights.findIndex((me) => me.modelElementId === modelElementId) > -1;
  }

  protected getModelElementHighlight(modelElementId: string): ModelDifferenceHighlightDTO | undefined {
    return this.modelDifferenceHighlights.find((me) => me.modelElementId === modelElementId);
  }

  protected isEdge(modelElement: ModelElement): boolean {
    return modelElement instanceof Edge;
  }

  public handleDragEnd(): void {
    if (this.selectedElements.length === 1) {
      const selectedElement = this.selectedElements[0];

      if (selectedElement.modelElement instanceof Drawable && selectedElement.modelElement.bounds) {
        selectedElement.modelElement.endPos = new Point(
          selectedElement.modelElement.startPos.x + selectedElement.modelElement.bounds.width,
          selectedElement.modelElement.startPos.y + selectedElement.modelElement.bounds.height
        );
      }
    }

    // set timeout to prevent mouseUp to be interpreted as click which would de-select the elements
    this.draggingTimeout = setTimeout(() => {
      this.isDragging = false;
    }, 200);
  }

  public handleDragGroupEnd(): void {
    // override parent method
  }

  protected handleSelectoDragStart(e): void {
    const target = e.inputEvent.target;
    if ((this.$refs.moveable as any).isMoveableElement(target)) {
      e.stop();
    }
  }

  protected onSelect(e) {
    let targets = e.selected.map((selElement) => {
      return selElement.querySelector("[data-element-id$='-select']");
    });

    let selectedElements: SelectedModelElement[] = [];

    targets
      .map((target) => {
        let tg = target.attributes.getNamedItem('data-element-id').value;
        let me = this.model.modelElements.find((me) => me.target === tg);
        return me ? new SelectedModelElement(me, tg) : null;
      })
      .forEach((selectedElement) => {
        if (selectedElement == null) return;

        selectedElements.push(selectedElement);

        if (targets.length == 1) {
          // select children only on single select
          let children = this.model.modelElements
            .filter((me) => me instanceof Node)
            .filter(
              (me) => (me as Node).parentId === selectedElement.modelElement.id && me !== selectedElement.modelElement
            );
          children.forEach((child) => {
            if (!selectedElements.find((se) => se.modelElement.id === child.id) && child.target) {
              selectedElements.push(new SelectedModelElement(child, child.target));
              targets.push(this.getTargetDomNode(child.target));
            }
          });
        }
      });

    this.setSelectedElements(selectedElements);
    this.targets = targets;

    if (e.isDragStart) {
      e.inputEvent.preventDefault();

      Vue.nextTick(() => {
        Vue.nextTick(() => {
          (this.$refs.moveable as any).dragStart(e.inputEvent);
        });
      });
    }
  }

  protected calculateViewBox(force?: boolean) {
    const sizeOffset = 50;
    let bounds = (this.$refs.drawContainer as HTMLElement).getBoundingClientRect();

    if (
      Math.abs(this.lastBounds.width - bounds.width) > sizeOffset ||
      Math.abs(this.lastBounds.height - bounds.height) > sizeOffset ||
      force
    ) {
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      let width = windowWidth * (bounds.width / windowWidth) * this.internalScale;
      let height = windowHeight * (bounds.height / windowHeight) * this.internalScale;

      this.lastBounds = new Bounds(bounds.width, bounds.height);

      this.viewBox = '0 0 ' + width + ' ' + height;
    }
  }
}
