/**
 * This class defines helpers used for manipulations of SVG strings
 * defined in model configs.
 */
export default class SVGHelper {
  /**
   * Function changes ids of edge markers in svg string given in config to
   * make it dynamic.
   * This prevents browsers from seeing multiple equal ids of markers.
   * This also prevents all edges from using first defined marker in Editor Sidebar
   * and Model Config View.
   *
   * @param displaySVG SVG string of SVGElement
   * @returns string SVG string of SVGElement with replace ids.
   */
  public static createDynamicEdgePathIds(displaySVG: string): string {
    const regex = [/marker-start=["']url\(#([a-zA-Z0-9-_]+)\)/g, /marker-end=["']url\(#([a-zA-Z0-9-_]+)\)/g];

    regex.forEach((reg) => {
      const match = reg.exec(displaySVG);

      if (match && match.length === 2) {
        const id = match[1];
        const rand = Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(0, 5);

        displaySVG = displaySVG.replaceAll(id, id + '-' + rand);
      }
    });

    return displaySVG;
  }
}
