import Model from '@/models/Model';
import OperationDTO from '@/model-difference/models/OperationDTO';
import ModelElement from '@/models/ModelElement';
import { deserialize, serialize } from 'typescript-json-serializer';
import { ModelDifferenceOperationTypesEnum } from '@/model-difference/enums/ModelDifferenceOperationTypesEnum';
import ModelDifferenceHighlightDTO from '@/model-difference/models/ModelDifferenceHighlightDTO';
import { fixNodeReferencesOfEdges } from '@/serializer/helpers';
import OperationsDTO from '@/model-difference/models/OperationsDTO';

export default class ModelDifferenceHelper {
  /**
   * Returns a list of tuples (operation type, model element id) that represents
   * model elements to be highlighted.
   * @param patch list of operations to be applied on a model
   */
  public static getModelDifferenceHighlights(patch: OperationDTO[]): ModelDifferenceHighlightDTO[] {
    const modelDifferenceHighlights: ModelDifferenceHighlightDTO[] = [];

    if (!patch) {
      return modelDifferenceHighlights;
    }

    // highlight changed elements
    patch.forEach((operation) => {
      // try to parse value as ModelElement
      let modelElement: ModelElement | null = null;
      if (operation.context !== null && typeof operation.context === 'object') {
        modelElement = deserialize(operation.context, ModelElement);
      } else if (operation.value !== undefined) {
        modelElement = deserialize(operation.value, ModelElement);
      }

      if (modelElement !== null) {
        modelDifferenceHighlights.push(
          new ModelDifferenceHighlightDTO(
            ModelDifferenceOperationTypesEnum[operation.op.toUpperCase()],
            modelElement.id
          )
        );
      }
    });

    return modelDifferenceHighlights;
  }
}
