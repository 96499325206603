import { render, staticRenderFns } from "./ModelDifferenceCanvas.vue?vue&type=template&id=65e4373a&scoped=true&"
import script from "./ModelDifferenceCanvas.vue?vue&type=script&lang=ts&"
export * from "./ModelDifferenceCanvas.vue?vue&type=script&lang=ts&"
import style0 from "./ModelDifferenceCanvas.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ModelDifferenceCanvas.vue?vue&type=style&index=1&id=65e4373a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e4373a",
  null
  
)

export default component.exports