import Component, { mixins } from 'vue-class-component';
import Model from '@/models/Model';
import { hasPermission } from '@/auth/AuthService';
import ModelService from '@/services/ModelService';
import { fixNodeReferencesOfEdges, fixNodeReferencesOfNodeParentId } from '@/serializer/helpers';
import { Toasts } from '@/mixins/ToastMixins';
import { ModelMixin } from '@/mixins/ModelMixin';
import { PossibleAction } from '@/auth/PossibleAction';

@Component({})
export class LoadModelMixin extends mixins(Toasts, ModelMixin) {
  public loadModel(modelId: string): Promise<Model> {
    if (hasPermission(PossibleAction.CAN_GET_MODEL)) {
      return ModelService.getById(modelId).then((model) =>
        fixNodeReferencesOfNodeParentId(fixNodeReferencesOfEdges(model))
      );
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }

    return new Promise<Model>((resolve, reject) => reject('Failed to load model'));
  }
}
