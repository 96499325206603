












































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FeatureMixin } from '@/mixins/FeatureMixin';
import Model from '@/models/Model';
import ReviewCanvasReadOnly from '@/components/Editor/ReviewCanvasReadOnly.vue';
import ModelDifferenceCanvas from '@/model-difference/components/ModelDifferenceCanvas.vue';
import ModelConfig from '@/models/ModelConfig';
import { LoadModelMixin } from '@/mixins/LoadModelMixin';
import { LoadModelConfig } from '@/mixins/LoadModelConfig';
import Point from '@/models/Point';
import OperationsDTO from '@/model-difference/models/OperationsDTO';
import ModelDifferenceService from '@/model-difference/services/ModelDifferenceService';
import ModelDifferenceHelper from '@/model-difference/helpers/ModelDifferenceHelper';
import OperationDTO from '@/model-difference/models/OperationDTO';
import Vue from 'vue';
import { ModelDifferenceOperationTypesEnum } from '@/model-difference/enums/ModelDifferenceOperationTypesEnum';
import ModelDifferenceHighlightDTO from '@/model-difference/models/ModelDifferenceHighlightDTO';
import { State } from 'vuex-class';
import { fixNodeReferencesOfEdges, fixNodeReferencesOfNodeParentId } from '@/serializer/helpers';
import { ProjectModels } from '@/mixins/ProjectMixins';

enum ViewTypes {
  INTEGRATED = 'integrated',
  SIDE_BY_SIDE = 'side-by-side',
}

@Component({
  components: { ModelDifferenceCanvas, ReviewCanvasReadOnly },
})
export default class ModelDifference extends mixins(FeatureMixin, LoadModelMixin, LoadModelConfig, ProjectModels) {
  // inject ViewTypes enum
  protected ViewTypes = ViewTypes;

  protected readonly breadcrumbs = [
    { text: 'Home', to: '/' },
    { text: 'Projects', to: '/projects' },
    { text: 'Compare Models', active: true },
  ];

  protected readonly highlightActions = [
    { text: 'Addition', value: ModelDifferenceOperationTypesEnum.ADD },
    { text: 'Deletion', value: ModelDifferenceOperationTypesEnum.REMOVE },
    { text: 'Modify', value: ModelDifferenceOperationTypesEnum.REPLACE },
  ];

  protected readonly viewTypes = [
    { text: 'Integrated', value: ViewTypes.INTEGRATED },
    { text: 'Side-By-Side', value: ViewTypes.SIDE_BY_SIDE },
  ];

  @Prop({
    required: false,
  })
  protected models!: Model[];

  @Prop({
    required: false,
  })
  protected modelIds!: string[];

  @Prop({
    required: true,
  })
  protected projectId!: number;

  protected selectedHighlightActions = [
    ModelDifferenceOperationTypesEnum.ADD,
    ModelDifferenceOperationTypesEnum.REMOVE,
    ModelDifferenceOperationTypesEnum.REPLACE,
  ];

  protected selectedViewType = ViewTypes.INTEGRATED;

  protected mirrorMovements = true;

  protected modelA: Model | null = null;
  protected modelB: Model | null = null;

  protected modelConfig: ModelConfig | null = null;

  protected modelAScale = 1;
  protected modelBScale = 1;

  protected modelAViewPort = new Point(0, 0);
  protected modelBViewPort = new Point(0, 0);

  @State('models', { namespace: 'project' })
  protected availableModelsInSameProject!: Model[];

  protected selectedModelAId: string | null = null;
  protected selectedModelBId: string | null = null;

  protected loadModelFromBackendEnabled = true;

  /**
   * Contains differences calculated by backend.
   * @protected
   */
  protected calculatedModelDifferences: OperationsDTO | null = null;

  /**
   * A list of pairs (operation type, model element id) that represents
   * the model elements to be highlighted
   * @protected
   */
  protected modelDifferenceHighlights: ModelDifferenceHighlightDTO[] = [];

  protected integratedModel: Model | null = null;

  mounted(): void {
    // load first two models in array
    // and their differences
    // TODO implement for n models?
    if (this.modelIds.length > 1) {
      this.selectedModelAId = this.modelIds[0];
      this.selectedModelBId = this.modelIds[1];

      /**
       * Load first model and model config
       */
      this.loadModelFromBackend(this.selectedModelAId, true).then((model) => (this.modelA = model));
      /**
       * Load second model
       */
      this.loadModelFromBackend(this.selectedModelBId).then((model) => (this.modelB = model));

      /**
       * Load model differences
       */
      this.loadModelDifferences(this.modelIds);
    }
    // load models of project (only used if page is refreshed; otherwise filled by projectsdetailview)
    if (this.models === undefined) {
      this.loadCurrentModels(this.projectId);
    }
  }

  @Watch('calculatedModelDifferences')
  protected handleModelDifferencesChange(newVal: OperationsDTO): void {
    if (newVal) {
      this.modelDifferenceHighlights = ModelDifferenceHelper.getModelDifferenceHighlights(newVal.patch);
    }
  }

  @Watch('selectedModelAId')
  protected handleSelectedModelAIdChange(newVal: string): void {
    if (newVal && this.loadModelFromBackendEnabled) {
      // only load model config if not present yet
      this.loadModelFromBackend(newVal, this.modelConfig === null).then((model) => (this.modelA = model));
      if (this.selectedModelBId !== null) {
        this.loadModelDifferences([newVal, this.selectedModelBId]);
      }
    }
  }

  @Watch('selectedModelBId')
  protected handleSelectedModelBIdChange(newVal: string): void {
    if (newVal && this.loadModelFromBackendEnabled) {
      // only load model config if not present yet
      this.loadModelFromBackend(newVal, this.modelConfig === null).then((model) => (this.modelB = model));
      if (this.selectedModelAId !== null) {
        this.loadModelDifferences([this.selectedModelAId, newVal]);
      }
    }
  }

  protected loadModelFromBackend(modelId: string, loadConfig = false): Promise<Model> {
    return this.loadModel(modelId).then((model) => {
      if (loadConfig) {
        this.loadModelConfig(model).then((modelConfig) => {
          if (modelConfig instanceof ModelConfig) {
            this.modelConfig = modelConfig;
          }
        });
      }

      return model;
    });
  }

  protected loadModels(): void {
    if (this.selectedModelAId == null || this.selectedModelBId == null) {
      console.error('Two models have to be selected to successfully load model differences');
      return;
    }
    /**
     * Load first model and model config
     */
    this.loadModelFromBackend(this.selectedModelAId, true);
    /**
     * Load second model
     */
    this.loadModel(this.selectedModelBId).then((model) => (this.modelB = model));
  }

  protected loadModelDifferences(modelIds: string[]): void {
    if (modelIds.length > 1) {
      ModelDifferenceService.getModelDifferences(modelIds[0], modelIds[1], true).then(
        (operationsDTO: OperationsDTO) => {
          operationsDTO.patch.forEach((operationDTO: OperationDTO) => {
            operationDTO.op = operationDTO.op.toLowerCase();
          });

          this.calculatedModelDifferences = operationsDTO;
          if (operationsDTO.integratedModel) {
            this.integratedModel = fixNodeReferencesOfNodeParentId(
              fixNodeReferencesOfEdges(operationsDTO.integratedModel)
            );
          }
        }
      );
    }
  }

  protected handleModelAScaleChange(value: number): void {
    if (this.mirrorMovements) {
      this.modelBScale = value;
    }
  }

  protected handleModelBScaleChange(value: number): void {
    if (this.mirrorMovements) {
      this.modelAScale = value;
    }
  }

  protected handleModelAViewPortChange(value: Point): void {
    if (this.mirrorMovements) {
      this.modelBViewPort = value;
    }
  }

  protected handleModelBViewPortChange(value: Point): void {
    if (this.mirrorMovements) {
      this.modelAViewPort = value;
    }
  }

  protected switchModels(): void {
    if (
      this.modelA === null ||
      this.modelB === null ||
      this.selectedModelAId === null ||
      this.selectedModelBId === null
    ) {
      // do nothing if one of both models is empty
      return;
    }

    // disable model fetching from backend
    this.loadModelFromBackendEnabled = false;

    // switch model ids
    const selectedModelAId = this.selectedModelAId;
    this.selectedModelAId = this.selectedModelBId;
    this.selectedModelBId = selectedModelAId;

    // switch models
    const modelA = this.modelA;
    this.modelA = this.modelB;
    this.modelB = modelA;

    Vue.nextTick(() => {
      // re-enable model fetching from backend
      this.loadModelFromBackendEnabled = true;

      this.modelDifferenceHighlights = [];
      this.calculatedModelDifferences = new OperationsDTO([]);
      if (this.modelA && this.modelA.id && this.modelB && this.modelB.id) {
        let modelIds = [this.modelA.id, this.modelB.id];
        this.loadModelDifferences(modelIds);
      }
    });
  }

  get classes(): string[] {
    let classes: string[] = ['assignments', 'px-3'];

    this.highlightActions.forEach((action) => {
      if (this.selectedHighlightActions.indexOf(action.value) > -1) {
        classes.push('show-highlight-' + action.value);
      }
    });

    return classes;
  }

  get modelSelectOptions() {
    return this.availableModelsInSameProject
      .filter((model) => this.modelConfig == null || model.modelConfigId === this.modelConfig.id)
      .map((model) => {
        let disabled = model.id === this.selectedModelAId || model.id === this.selectedModelBId;
        return {
          text: model.name,
          value: model.id,
          disabled: disabled,
        };
      })
      .sort((a, b) => {
        let aName = a.text.toUpperCase();
        let bName = b.text.toUpperCase();

        if (aName < bName) {
          return -1;
        } else if (aName > bName) {
          return 1;
        }

        return 0;
      });
  }
}
