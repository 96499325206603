
















import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ColorHighlightSvgMixin } from '@/components/Editor/Highlight/ColorHighlightSvgMixin';

@Component
export default class NodeColorHighlightSvg extends mixins(ColorHighlightSvgMixin) {}
