import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModelElement from '@/models/ModelElement';
import Vue from 'vue';
import Config from '@/config';

export interface ColorHighlightSvgStyleConfiguration {
  strokeColor: string;
  strokeWidth: number;
}

@Component({})
export class ColorHighlightSvgMixin extends Vue {
  @Prop({
    required: true,
  })
  protected modelElement!: ModelElement;

  @Prop({
    required: false,
    default: () => {
      return Config.EDITOR.SELECTED_ELEMENT_DEFAULT_CONFIG;
    },
  })
  protected styleConfiguration!: ColorHighlightSvgStyleConfiguration;
}
