import Drawable from '@/models/drawables/Drawable';
import { Serializable } from 'typescript-json-serializer';
import Point from '@/models/Point';
import Bounds from '@/models/Bounds';

@Serializable()
export default class DefectAreaDrawable extends Drawable {
  public readonly vueComponent: string = 'area-drawable-svg-component';

  constructor(type: string, name: string, description: string, startPos: Point, bounds: Bounds, created?: Date) {
    super(type, name, description, startPos, bounds, created);
  }
}
