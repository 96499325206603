import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import ModelElement from '@/models/ModelElement';
import { ModelDifferenceOperationTypesEnum } from '@/model-difference/enums/ModelDifferenceOperationTypesEnum';
import ModelDifferenceHighlightDTO from '@/model-difference/models/ModelDifferenceHighlightDTO';

@Component({})
export class ModelDifferenceColorHighlightSvgMixin extends Vue {
  @Prop({
    required: true,
  })
  protected modelElement!: ModelElement;

  @Prop({
    required: true,
  })
  protected modelElementHighlight!: ModelDifferenceHighlightDTO;

  get strokeColor(): string {
    switch (this.modelElementHighlight.type) {
      case ModelDifferenceOperationTypesEnum.REMOVE:
        return 'red';
      case ModelDifferenceOperationTypesEnum.ADD:
        return 'green';
      case ModelDifferenceOperationTypesEnum.REPLACE:
      case ModelDifferenceOperationTypesEnum.MOVE:
        return 'orange';
    }

    return 'transparent';
  }
}
