import { ModelDifferenceOperationTypesEnum } from '@/model-difference/enums/ModelDifferenceOperationTypesEnum';

export default class ModelDifferenceHighlightDTO {
  private _type: ModelDifferenceOperationTypesEnum;
  private _modelElementId: string;

  constructor(type: ModelDifferenceOperationTypesEnum, modelElementId: string) {
    this._type = type;
    this._modelElementId = modelElementId;
  }

  get type(): ModelDifferenceOperationTypesEnum {
    return this._type;
  }

  set type(value: ModelDifferenceOperationTypesEnum) {
    this._type = value;
  }

  get modelElementId(): string {
    return this._modelElementId;
  }

  set modelElementId(value: string) {
    this._modelElementId = value;
  }
}
