































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ModelElement from '@/models/ModelElement';
import ReviewCanvas from '@/components/Editor/ReviewCanvas.vue';
import { ModelElementMarkerTypeEnum } from '@/enums/ModelElementMarkerTypeEnum';
import { mixins } from 'vue-class-component';
import ModelElementMarker from '@/models/ModelElementMarker';
import { Action } from 'vuex-class';
import Point from '@/models/Point';
import Intersects from 'intersects';
import Edge from '@/models/Edge';
import NodeColorHighlightSvg from '@/components/Editor/Highlight/NodeColorHighlightSvg.vue';
import EdgeColorHighlightSvg from '@/components/Editor/Highlight/EdgeColorHighlightSvg.vue';
import { VueSelecto } from 'vue-selecto';
import Drawable from '@/models/drawables/Drawable';
import Bounds from '@/models/Bounds';
import SelectedModelElement from '@/models/SelectedModelElement';
import DefectAreaDrawable from '@/models/drawables/DefectAreaDrawable';

@Component({
  components: { NodeColorHighlightSvg, EdgeColorHighlightSvg, VueSelecto },
})
export default class ReviewCanvasReadOnly extends mixins(ReviewCanvas) {
  @Action('addMarker', { namespace: 'modelElementMarkers' })
  protected addMarker!: (marker: ModelElementMarker) => void;

  @Action('setMarkers', { namespace: 'modelElementMarkers' })
  protected setMarkers!: (markers: ModelElementMarker[]) => void;

  @Action('loadMarkers', { namespace: 'modelElementMarkers' })
  protected loadMarkers!: () => void;

  @Prop({
    required: false,
    default: () => [],
  })
  protected highlightedModelElements!: string[];

  protected defectMarker: Drawable[] = [];

  mounted(): void {
    (this.$refs.canvas as HTMLElement).addEventListener('click', this.handleMarkerClick);
    this.loadMarkers();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  handleDrag({ beforeTranslate, target, transform }: { beforeTranslate: any; target: any; transform: any }): void {
    // do nothing
    if (this.selectedElements[0].modelElement instanceof DefectAreaDrawable) {
      this.selectedElements[0].modelElement.startPos = new Point(beforeTranslate[0], beforeTranslate[1]);
    }
  }

  protected handleKeyDown(event: KeyboardEvent): void {
    // ignore
    if (event.code.toLowerCase() === 'space') {
      event.preventDefault();
      event.stopPropagation();
      this.dragscrollEnabled = true;
    }
  }

  protected handleKeyUp(event: KeyboardEvent): void {
    // ignore
    if (event.code.toLowerCase() === 'space') {
      event.preventDefault();
      event.stopPropagation();
      this.dragscrollEnabled = false;
    }
  }

  protected handleResize({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    target,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    width,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    height,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    drag,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clientX,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clientY,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    offsetWidth,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    offsetHeight,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    datas,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    target: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    width: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    height: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    drag: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    clientX: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    clientY: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    offsetWidth: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    offsetHeight: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    datas: any;
  }): void {
    // do nothing
  }

  public handleDragGroup({ events }: { events: any }): void {
    // do nothing
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleDrop(e: Event): void {
    // do nothing
  }

  protected deleteSelectedElements(): void {
    // do nothing
  }

  protected get resizable(): boolean {
    return true; // (this.selectedElements.length > 0 && this.selectedElements[0].modelElement instanceof DefectAreaDrawable);
  }

  protected get draggable(): boolean {
    return true;
  }

  protected isEdge(modelElement: ModelElement): boolean {
    return modelElement instanceof Edge;
  }

  protected modelElementIsSelected(modelElementId: string): boolean {
    return this.highlightedModelElements.indexOf(modelElementId) > -1;
  }

  protected handleMarkerClick(e: MouseEvent): void {
    if (this.isDragging) {
      return;
    }
    const drawContainer = this.$refs.drawContainer as HTMLDivElement;
    // bounds of drawContainer
    const bounds = drawContainer.getBoundingClientRect();

    // calculate position
    const x = e.clientX / (1 / this.scale) - bounds.x / (1 / this.scale) - this.internalViewPort.x / (1 / this.scale);
    const y = e.clientY / (1 / this.scale) - bounds.y / (1 / this.scale) - this.internalViewPort.y / (1 / this.scale);

    const elementBounds = 40 / (1 / this.scale);
    const offset = 20 / (1 / this.scale);
    const modelMarkers: ModelElementMarker[] = [];

    if (this.selectedMarkerType === ModelElementMarkerTypeEnum.REMOVE) {
      this.modelMarkers.forEach((marker: ModelElementMarker) => {
        if (
          !Intersects.boxBox(
            x - offset,
            y - offset,
            offset * (2 / (1 / this.scale)),
            offset * (2 / (1 / this.scale)),
            marker.position.x,
            marker.position.y,
            elementBounds,
            elementBounds
          )
        ) {
          modelMarkers.push(marker);
        }
      });
      this.setMarkers(modelMarkers);
    } else if (this.selectedMarkerType) {
      // either OK, DESIGN_IMPROVEMENT, ERROR or UNCLEAR
      this.addMarker(
        new ModelElementMarker(
          this.selectedMarkerType,
          new Point(x - elementBounds / (2 / (1 / this.scale)), y - elementBounds / (2 / (1 / this.scale)))
        )
      );
    }
  }
}
