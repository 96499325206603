















import { Component } from 'vue-property-decorator';
import Point from '@/models/Point';
import { mixins } from 'vue-class-component';
import { ModelDifferenceColorHighlightSvgMixin } from '@/model-difference/mixins/ModelDifferenceColorHighlightSvgMixin';

@Component
export default class ModelDifferenceEdgeColorHighlightSvg extends mixins(ModelDifferenceColorHighlightSvgMixin) {
  get startPosition(): Point {
    return this.modelElement.startPos;
  }

  get endPosition(): Point {
    return this.modelElement.endPos;
  }

  get edgeWidth(): number {
    if (this.startPosition && this.endPosition) {
      return Math.sqrt(
        Math.pow(this.endPosition.x - this.startPosition.x, 2) + Math.pow(this.endPosition.y - this.startPosition.y, 2)
      );
    }

    return 0;
  }

  get deltaX(): number {
    if (this.endPosition && this.startPosition) {
      return this.endPosition.x - this.startPosition.x;
    }

    return 0;
  }

  get deltaY(): number {
    if (this.endPosition && this.startPosition) {
      return this.endPosition.y - this.startPosition.y;
    }

    return 0;
  }

  get rotation(): number {
    return (Math.atan2(this.deltaY, this.deltaX) * 180) / Math.PI;
  }

  get transformString(): string {
    return 'transform: rotate(' + this.rotation + 'deg)';
  }

  get edgePath(): string {
    if (this.startPosition && this.endPosition) {
      let path = 'M';

      const offset = 10;

      path += '0,-' + offset;
      path += ' L' + this.edgeWidth + ',-' + offset;
      path += ' L' + this.edgeWidth + ',' + offset;
      path += ' 0, ' + offset;

      path += 'Z';

      return path;
    }

    return '';
  }
}
