import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import OperationsDTO from '@/model-difference/models/OperationsDTO';
import ModelElement from '@/models/ModelElement';

export default class ModelDifferenceService {
  private static mainURL = '/model-difference';

  public static getModelDifferences(
    modelAId: string,
    modelBId: string,
    withIntegratedModel = false
  ): Promise<OperationsDTO> {
    return MainService.get(this.mainURL, { params: { modelAId, modelBId, withIntegratedModel } }).then((response) => {
      return deserialize(response.data, OperationsDTO);
    });
  }

  public static getModelElementDifferences(
    modelElementA: ModelElement,
    modelElementB: ModelElement
  ): Promise<OperationsDTO> {
    return MainService.post(this.mainURL + '/model-elements', { modelElementA, modelElementB }).then((response) => {
      return deserialize(response.data, OperationsDTO);
    });
  }
}
